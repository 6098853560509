<template>
  <div class="container">
    <TitleBarBaseVue :handle="{ isSave: false }" @handleSave="handleSave" />
    <div class="header">微信公众号小程序,完成以下设置：</div>
    <div class="block">
      <div class="title">1.进入"设置>开发设置",填写以下信息：</div>
      <el-form label-width="auto" label-position="left">
        <el-form-item prop="WxForm.appid" label="AppId(应用ID)">
          <el-input :readonly="true"></el-input>
        </el-form-item>
        <el-form-item prop="WxForm.appSecret" label="AppSecret(密钥)">
          <el-input :readonly="true"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <div class="title">2.消息推送：</div>
      <el-row class="rowdata">
        <el-col :span="4">URL</el-col>
        <el-col :span="20">{{ WxForm.messageUrl }}</el-col>
      </el-row>
      <el-row class="rowdata">
        <el-col :span="4">Token</el-col>
        <el-col :span="20">{{ WxForm.token }}</el-col>
      </el-row>
      <el-row class="rowdata">
        <el-col :span="4">EncodingAESKey</el-col>
        <el-col :span="20">{{ WxForm.encodingAesKey }}</el-col>
      </el-row>
      <el-row class="rowdata">
        <el-col :span="4">消息加解密方式</el-col>
        <el-col :span="20">{{ WxForm.msgEncryptionMethod }}</el-col>
      </el-row>
      <el-row class="rowdata">
        <el-col :span="4">数据格式</el-col>
        <el-col :span="20">{{ WxForm.msgDataFormat }}</el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { sysInfo, updateSysSetting } from '@/api/systemController'
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
export default {
  data() {
    return {
      WxForm: []
    }
  },
  methods: {
    async getWxInfo() {
      let [err, res] = await updateSysSetting()
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取失败')
      }
      //console.log(res)
      // this.$message.success(res.msg || '获取成功')
      this.WxForm = res.data
    },
    async handleSave() {
      // if (!this.sysform.logo) this.$message.error('请上传logo')
      // console.log(this.sysform)
      // let [err, res] = await updateSysInfo(this.sysform)
      // if (err) {
      //   console.log(err)
      //   return this.$message.error(err.msg || '保存失败')
      // }
      // //console.log(res)
      // this.$message.success(res.msg || '保存成功')
    }
  },
  mounted() {
    this.getWxInfo()
  },
  components: {
    TitleBarBaseVue
  }
}
</script>
<style lang="less" scoped>
@import url(./index.less);
.rowdata {
  margin: 12px 0 5px 5px;
  font-size: 100%;
  color: #606266;
  font: inherit;
}
</style>
